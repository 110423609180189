import { useIntl } from 'react-intl';

import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import messages from './Pending.messages';
import { NavLink } from 'react-router-dom';
import Button from '@luna-protocol/core/src/components/Button/Button';
import { getDealerApp } from '../../utils/getDealerApp.ts';

const Pending = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Banner>{formatMessage(messages.title)} </Banner>
      <Body>
        <p>{formatMessage(messages.detail1)}</p>
        <Button>
            <NavLink to={`${getDealerApp()}/prequalification`}>{formatMessage(messages.returnButton)}</NavLink>
        </Button>
      </Body>
    </>
  );
};

export default Pending;