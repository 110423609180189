import { defineMessages } from 'react-intl';

export const modalNames = {
  ElectronicDisclosuresPolicy: 'Consent to Electronic Disclosures',
  TermsOfUse: 'Application Terms of Use',
  CreditReportAuthorization: 'Credit Report Authorization',
};

const messages = defineMessages({
  title: {
    id: 'application.title',
    defaultMessage: 'Your details',
  },
  coapplicantSubtitle: {
    id: 'application.coapplicantSubtitle',
    defaultMessage:
      'This request for financing is a joint application with the person indicated below.  If you do not recognize their name or email address, or do not want to apply for financing, do not proceed with submitting your details.  If approved, both you and the applicant must sign the contract agreement on the same day.',
  },
  notice: {
    id: 'application.notice',
    defaultMessage: 'All fields are mandatory, unless otherwise noted.',
  },
  applicantFormTitle: {
    id: 'application.applicantFormTitle',
    defaultMessage: 'About you',
  },
  coapplicantFormTitle: {
    id: 'application.coapplicantFormTitle',
    defaultMessage: 'Your co-applicant',
  },
  optional: {
    id: 'application.optional',
    defaultMessage: 'optional',
  },
  textPlaceholder: {
    id: 'application.textPlaceholder',
    defaultMessage: 'Please enter',
  },
  firstName: {
    id: 'application.firstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'application.lastName',
    defaultMessage: 'Last name',
  },
  primaryFirstName: {
    id: 'application.primaryFirstName',
    defaultMessage: 'Primary first name',
  },
  primaryLastName: {
    id: 'application.primaryLastName',
    defaultMessage: 'Primary last name',
  },
  streetAddress: {
    id: 'application.streetAddress',
    defaultMessage: 'Street address',
  },
  city: {
    id: 'application.city',
    defaultMessage: 'City',
  },
  state: {
    id: 'application.state',
    defaultMessage: 'State',
  },
  county: {
    id: 'application.county',
    defaultMessage: 'County',
  },
  zipCode: {
    id: 'application.zipCode',
    defaultMessage: 'Zip code',
  },
  phoneNumber: {
    id: 'application.phoneNumber',
    defaultMessage: 'Phone number',
  },
  phoneNumberCoAuthorization: {
    id: 'application.phoneNumberCoAuthorization',
    defaultMessage:
      'You authorize us to call you, using an automated system, at this number (or any number later provided) with information about your application.',
  },
  homeNumber: {
    id: 'application.homeNumber',
    defaultMessage: 'Home phone number',
  },
  emailAddress: {
    id: 'application.emailAddress',
    defaultMessage: 'Email address',
  },
  residenceType: {
    id: 'application.residenceType',
    defaultMessage: 'Residence type',
  },
  grossIncome: {
    id: 'application.grossIncome',
    defaultMessage: 'Gross annual income',
  },
  grossIncomeDetails: {
    id: 'application.grossIncomeDetails',
    defaultMessage:
      'Include Your Total Monthly Gross Income (Do not include alimony, child support or any other income that you do not want considered as a basis for repaying this obligation.)',
  },
  grossIncomeCoDetails: {
    id: 'application.grossIncomeCoDetails',
    defaultMessage:
      'Include all sources, such as salary, interest, dividends, rental income or any other income before taxes or payroll deductions. Do not include alimony, child support or any other income that you do not want considered as a basis for repaying this obligation. Multiply non-taxable income by 1.33 before adding to gross income (e.g., $100 non-taxable income = 100 x 1.33 = $133).',
  },
  coApplicant: {
    id: 'application.coApplicant',
    defaultMessage: 'Add another person to this pre-qualification request',
  },
  ssn: {
    id: 'application.ssn',
    defaultMessage: 'Last four digits of social security number (SSN)',
  },
  fullSsn: {
    id: 'application.fullSsn',
    defaultMessage: 'Social security number (SSN)',
  },
  amountRequested: {
    id: 'application.amountRequested',
    defaultMessage: 'Credit amount requested',
  },
  consentTitle: {
    id: 'application.consentTitle',
    defaultMessage: 'Consent to Electronic Disclosures',
  },
  productInformation: {
    id: 'application.productInformation',
    defaultMessage: 'Product information',
    fields: {
      make: {
        id: 'application.productInformation.make',
        defaultMessage: 'Make',
      },
      model: {
        id: 'application.productInformation.model',
        defaultMessage: 'Model',
      },
      price: {
        id: 'application.productInformation.price',
        defaultMessage: 'Price',
      },
      priceDisclaimer: {
        id: 'application.productInformation.priceDisclaimer',
        defaultMessage:
          'The pre-qualification you receive will be based on the price of the base unit, therefore if this changes throughout the process, the amount of financing you may be eligible for may change.',
      },
      new: {
        id: 'application.productInformation.new',
        defaultMessage: 'New',
      },
      used: {
        id: 'application.productInformation.used',
        defaultMessage: 'Used',
      },
    },
  },
  consentAgreement: {
    id: 'application.consentAgreement',
    defaultMessage: 'You have read, understood and consent to the Consent to Electronic Disclosures Agreement',
  },
  consentData: {
    id: 'application.consentData',
    defaultMessage: 'You consent to share your data and information with the dealership',
  },
  creditReportAuthorization: {
    id: 'application.creditReportAuthorization',
    defaultMessage: 'Credit Report Authorization',
  },
  termsDetails: {
    id: 'application.termsDetails',
    defaultMessage: 'Read each policy carefully and confirm that you accept by using the check box below.',
    items: {
      item1Title: {
        id: 'application.termsDetails.item1Title',
        defaultMessage: 'Credit Report Authorization',
      },
      item1: {
        id: 'application.termsDetails.item1',
        defaultMessage:
          'Read the {url}, which gives us permission to obtain credit reports and other credit information on you related to this prequalification request and any related credit requests in the future.',
      },
      item2Title: {
        id: 'application.termsDetails.item2Title',
        defaultMessage: 'Terms of Use',
      },
      item2: {
        id: 'application.termsDetails.item2',
        defaultMessage: 'Our Website {url}.',
      },
    },
  },
  termsAgreement: {
    id: 'application.termsAgreement',
    defaultMessage: 'You have read, understood and consent to Credit Report Authorization',
  },
  submit: {
    id: 'application.submit',
    defaultMessage: 'Pre-qualify now',
  },
  readMore: {
    id: 'application.readmore',
    defaultMessage: 'Read more',
  },
  yearsInCurrentEmployment: {
    id: 'application.yearsInCurrentEmployment',
    defaultMessage: 'Years in current employment',
  },
  yearsInCurrentResidence: {
    id: 'application.yearsInCurrentResidence',
    defaultMessage: 'Years in current residence',
  },
  dateOfBirth: {
    id: 'application.dateOfBirth',
    defaultMessage: 'Date of birth',
  },
  softPullMessage: {
    id: 'application.softPullMessage',
    defaultMessage: 'This pre-qualification will not impact your credit score',
  },
  returnButton: {
    id: 'application.return',
    defaultMessage: 'Return to Homepage',
  },
  dealerError: {
    id: 'application.dealerError',
    defaultMessage: 'An unexpected error occurred. Please try again.',
  },
  esignPretext: {
    id: 'application.esignPretext',
    defaultMessage:
      'Read the Consent to Electronic Disclosures and Signatures Agreement which gives us permission to send you disclosures electronically and to obtain signatures electronically.',
  },
});

export default messages;

export class electronicDisclosure {}
